<template>
  <div class="to-do-list">
    <p class="total">{{ $t("共") }} {{ totalCount }} {{ $t("条数据") }}</p>
    <List v-if="$store.state.user.userInfo.id" ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item }">
        <Card
          :data="item"
          :is-todo="true"
          class="card"
          :yuqi="
            $moment().diff(item.planTime, 'hours') > 0
              ? getYuQiTime(item.planTime)
              : ''
          ">
        </Card>
      </template>
    </List>
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { dateFormat } from '@/utils'
import Card from '@/modules/appointment/component/card.vue'
import { getAppointmentPage } from '@/services/appointment'
import { mapGetters } from 'vuex'
let vm = this
export default {
  name: 'SchedulingDrive',
  components: {
    List,
    CardOptions,
    Card,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
    })
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  data() {
    return {
      totalCount: 0,
      taskCode: this.$route.query.taskCode,
      start: this.$route.query.start,
      end: this.$route.query.end,
    }
  },
  
  computed: {
    ...mapGetters(['dictHash']),
    taskData() {
      let nameConfig = {}
      let codeConfig = {}
      const options = []
      this.$store.getters.getDictHash(5001)?.map((item) => {
        nameConfig[item.code] = item.name
        if (item.descr) {
          codeConfig[item.code] = item.descr
          options.push({
            ...item,
            text: item.name,
            value: item.code,
          })
        }
        return
      })
      return {
        options,
        nameConfig,
        codeConfig,
      }
    },
  },
  watch: {
    dictHash: {
      handler(val) {
        this.onRefresh()
      },
      immediate: true
    }
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    getYuQiTime(planTime) {
      if (this.$moment().diff(planTime, 'hours') > 0) {
        const diff = this.$moment().diff(planTime, 'seconds')
        // 将秒数转换为天和小时
        const days = Math.floor(diff / (24 * 3600))
        const hours = Math.floor((diff % (24 * 3600)) / 3600)
        if (days && hours) {
          return `${this.$t('逾期时长')}: ${days}${this.$t(
            '天'
          )}${hours}${this.$t('小时')}`
        } else if (days) {
          return `${this.$t('逾期时长')}: ${days}${this.$t('天')}`
        } else if (hours) {
          return `${this.$t('逾期时长')}: ${hours}${this.$t('小时')}`
        }
      } else {
        return ''
      }
    },

    onConfirm() {
      this.onRefresh()
    },
    onRefresh() {
      this.$refs?.list?.onRefresh()
    },
    fetchApi(page) {
      const { currentPage,pageSize } = page
      const { type, roleCode, start, end, followUserIds, parentCode='', childCode='', dealerProvinceCode='', dealerCityCode='', dealerIds='' } = this.$route.query
      const params = {
        roleCode,
        pageNum: currentPage,
        pageSize,
        driveFlag: true,
        followUserIds: followUserIds ? followUserIds?.split(',') : [],
        parentCode: parentCode || '',
        childCode: childCode ? [childCode] : [],
        dealerProvinceCode: dealerProvinceCode || '',
        dealerCityCode: dealerCityCode || '',
        dealerIds: dealerIds ? [dealerIds] : []
      }
      if (type === 'APPOINTMENT_TARGET') {
        // 排程量
        params.createTime = {
          start: dateFormat(start,'YYYY-MM-DD 00:00:00'),
          end: dateFormat(end,'YYYY-MM-DD 23:59:59'),
        }
        params.searchType = 1
      } else {
        // 试驾量
        params.driveEndTimeRange = {
          start: dateFormat(start,'YYYY-MM-DD 00:00:00'),
          end: dateFormat(end,'YYYY-MM-DD 23:59:59'),
        }
        params.searchType = 2
      }
      loading.showLoading()
      return getAppointmentPage(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
  },
}
</script>
<style lang="less" scoped>
.card {
  /deep/.bottom {
    height: auto;
    line-height: inherit;
    padding-top: 8px;
    align-items: center;
    margin-bottom: -4px;
  }
  .todo-actions {
    display: flex;
  }
}
.order {
  /deep/ .bottom {
    border: none;
    padding-top: 0;
    flex-wrap: wrap;
  }
  .todo-actions {
    margin-top: 8px;
    border-top: 1px solid rgba(13, 23, 26, 0.05);
    padding-top: 8px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.assigned {
  align-self: stretch;
  display: flex;
  align-items: center;
  color: #b9921a;
  cursor: pointer;
}
/deep/ .van-dropdown-menu__bar {
  padding-left: 8px;
  padding-right: 16px;
  justify-content: space-between;
}
/deep/ .van-dropdown-item__content {
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/ .van-dropdown-menu__item {
  flex: none;
}
.to-do-list {
  .yuqi {
    margin-right: auto;
    color: #e4002c;
		align-self: center;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .total {
    height: 32px;
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: #262626;
    line-height: 22px;
    // margin: 9px 16px 0;
    box-sizing: border-box;
    padding: 10px 0 0 16px;
  }
  .item-wrap {
    position: relative;
    background: white;
    padding: 16px 12px 0 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 13px;
    .user-info {
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    }
    .actions {
      border-top: 1px solid rgba(13, 23, 26, 0.05);
      height: 44px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      .ml-16 {
        margin-left: 16px;
      }
    }
  }
}
</style>
